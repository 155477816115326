import { Event } from 'types/event'

import Ogolnopolskie from './images/ogolnopolskie-gdansk.jpg'

const events: Event[] = [
  {
    id: 'spotkanie-ogolnopolskie-gdansk',
    name: 'Moc Eucharystii - Spotkanie Ogólnopolskie (Bydgoszcz)',
    attention: 'Zapraszamy!',
    location: 'Bydgoszcz',
    startDate: '14-09-2024',
    startTime: '09:45',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Ogolnopolskie,
  },
]

export default events
